.chip {
    label {
        width: 28px !important;
        height: 28px !important;
        border: 1px solid #555 !important;
        padding: 0px;
        justify-content: center;
        span:nth-of-type(1) {
            justify-content: center;
            svg {
                mix-blend-mode: difference;
            }
        }
        span:nth-of-type(2) {
            display: none;
        }
    }
}

.label {
    width: 30px !important;
    border: 1px solid #555 !important;
}